import layout2Settings from "./Layout2/Layout2Settings";
export const GullLayoutSettings = {
  activeLayout: "layout2",
  dir: "ltr",
  layout2Settings,
  customizer: {
    show: false,
    open: false
  },
  footer: {
    show: false
  }
};
