import React, { Component } from "react";
import { Button, Card, CardGroup, Col, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getDashboard } from "./DashboardService";
import { withRouter } from "react-router-dom";
import { isMobile } from "@utils";
class dashboard extends Component {
  state = { pending: {}, total: {}, bardana: [] };
  subTotalCost = 0
  async componentDidMount() {
    getDashboard().then((res) => {
      console.log(res)
      this.setState({ bardana: res.data.bardana, total: res.data.total, pending: res.data.pending });
    })
  }
  render() {
    return (
      <>
        <Row xs={1} md={3} className="g-4">
          {
            Object.keys(this.state.pending).map((item, index) => {
              return (
                  <Col key={index}>
                    <Card style={{ width: '18rem', marginBottom: "3px" }} className="bg-success">
                      <Card.Body>
                        <Card.Title className="text-white"><b>{item.toLocaleUpperCase()}</b></Card.Title>
                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                        <Card.Text>
                          <Table responsive className="table-success">
                            <tbody>
                              <tr style={{ backgroundColor: '#B4D1CF !important' }} >
                                <th>GENES</th>
                                {this.state.pending[item].map((it, index) => (
                                  <td key={index}>{it.genes=="paddy"?it.genes.toUpperCase():it.genes.replace("paddy","").toUpperCase()}</td>
                                ))}
                              </tr>
                              <tr>
                                <th>BAGS</th>
                                {this.state.pending[item].map((it, index) => (
                                  <td key={index}>{it.pending__sum + " " + it.unit.toUpperCase()}</td>
                                ))}
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
              )
            })}
          
          {
            Object.keys(this.state.total).map((item, index) => {
              return (
                <Col key={index+100}>
                    <Card style={{ width: '18rem', marginBottom: "3px"}} className="bg-info  text-white">
                      <Card.Body>
                      <Card.Title className="text-white"><b>{item.toLocaleUpperCase()}</b></Card.Title>
                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                        <Card.Text>
                          <Table responsive info>
                            <tbody>
                              <tr className="table-info">
                                <th>GENES</th>
                                {this.state.total[item].map((it, index) => (
                                  <td key={index}>{it.genes=="paddy"?it.genes.toUpperCase():it.genes.replace("paddy","").toUpperCase()}</td>
                                ))}
                              </tr>
                              <tr className="table-info">
                                <th>BAGS</th>
                                {this.state.total[item].map((it, index) => (
                                  <td key={index}>{it.quantity__sum + " " + it.unit.toUpperCase()}</td>
                                ))}
                              </tr>
                            </tbody>
                          </Table>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
              )
            })}
            {this.state.bardana.length > 0 && <Col key={"abc"}>
            <Card style={{ width: '18rem', marginBottom: "3px"}} className="bg-warning">
              <Card.Body>
                <Card.Title>Bardana</Card.Title>
                {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                <Card.Text>
                  <Table responsive >
                    <tbody>
                      <tr className="table-warning">
                        <th>Party Name</th>
                        {this.state.bardana.map((item, index) => (
                          <th key={index}>{item.party_name}</th>
                        ))}
                      </tr>
                      <tr className="table-warning">
                        <th>Bags</th>
                        {this.state.bardana.map((item, index) => (
                          <td key={index}>{item.quantity}</td>
                        ))}
                      </tr>
                      <tr className="table-warning">
                        <th>Quality</th>
                        {this.state.bardana.map((item, index) => (
                          <td key={index}>{item.quality}</td>
                        ))}
                      </tr>

                    </tbody>
                  </Table>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>}
        </Row>
      </>
    );
  }
}

export default withRouter(dashboard);