const layout2Settings = {
  leftSidebar: {
    theme: "sidebar-gradient-steel-gray",
  },
  header: {
    show: true
  },
  searchBox: {
    open: false
  },
  secondarySidebar: { show: true },
};

export default layout2Settings;
