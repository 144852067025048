export const GenesGroup = [
      { label: "Maize", value: "maize", val: "genes" },
      { label: "Paddy", value: "paddy", val: "genes" },
      { label: "Paddy Taj", value: "paddy taj", val: "genes" },
      { label: "Paddy 1711", value: "paddy 1711", val: "genes" },
      { label: "Paddy 1847", value: "paddy 1847", val: "genes" },
      { label: "Paddy 1692", value: "paddy 1692", val: "genes" },
      { label: "Paddy Sugandh", value: "paddy sugandh", val: "genes" },
      { label: "Paddy Sarbati", value: "paddy sarbati", val: "genes" },
      { label: "Paddy 1509", value: "paddy 1509", val: "genes" },
      { label: "Paddy RS10", value: "paddy RS10", val: "genes" },
      { label: "Paddy 1121", value: "paddy 1121", val: "genes" },
      { label: "Paddy 1711 2nd", value: "paddy 1711 2", val: "genes" },
      { label: "Paddy 1847 2nd", value: "paddy 1847 2", val: "genes" },
      { label: "Paddy 1692 2nd", value: "paddy 1692 2", val: "genes" },
      { label: "Paddy Sugandh 2nd", value: "paddy sugandh 2", val: "genes" },
      { label: "Paddy Sarbati 2nd", value: "paddy sarbati 2", val: "genes" },
      { label: "Paddy 1509 2nd", value: "paddy 1509 2", val: "genes" },
      { label: "Paddy RS10 2nd", value: "paddy RS10 2", val: "genes" },
      { label: "Paddy 1121 2nd", value: "paddy 1121 2", val: "genes" },
      { label: "Bajra", value: "bajra", val: "genes" },
      { label: "Wheat", value: "wheat", val: "genes" },
      { label: "Mustard Seeds", value: "mustard_seed", val: "genes" },
]
export const StateGroup = [
      { label: "Andhra Pradesh", value: 37 },
      { label: "Arunachal Pradesh", value: 12 },
      { label: "Assam", value: 18 },
      { label: "Bihar", value: 10 },
      { label: "Chattisgarh", value: 22 },
      { label: "Delhi", value: 7 },
      { label: "Goa", value: 30 },
      { label: "Gujarat", value: 24 },
      { label: "Haryana", value: 6 },
      { label: "Himachal Pradesh", value: 2 },
      { label: "Jammu and Kashmir", value: 1 },
      { label: "Jharkhand", value: 20 },
      { label: "Karnataka", value: 29 },
      { label: "Kerala", value: 32 },
      { label: "Lakshadweep Islands", value: 31 },
      { label: "Madhya Pradesh", value: 23 },
      { label: "Maharashtra", value: 27 },
      { label: "Manipur", value: 14 },
      { label: "Meghalaya", value: 17 },
      { label: "Mizoram", value: 15 },
      { label: "Nagaland", value: 13 },
      { label: "Odisha", value: 21 },
      { label: "Pondicherry", value: 34 },
      { label: "Punjab", value: 3 },
      { label: "Rajasthan", value: 8 },
      { label: "Sikkim", value: 11 },
      { label: "Tamil Nadu", value: 33 },
      { label: "Telangana", value: 36 },
      { label: "Tripura", value: 16 },
      { label: "Uttar Pradesh", value: 9 },
      { label: "Uttarakhand", value: 5 },
      { label: "West Bengal", value: 19 },
]
